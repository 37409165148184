import React from "react"
import { Carousel } from "react-bootstrap"
import ReactMarkdown from "react-markdown"

const Testimonials = ({ testimonials }) => {
  return (
    <div className="my-5">
      <Carousel
        className="comments-carousel"
        slide={testimonials.length > 1}
        indicators={testimonials.length > 1}
        controls={testimonials.length > 1}
      >
        {testimonials.map(testimonial => (
          <Carousel.Item interval={10000} key={testimonial.name}>
            <div className="d-flex flex-column align-items-center justify-content-center text-center p-5">
              <blockquote className="blockquote text-center px-3">
                <ReactMarkdown
                  source={testimonial.testimonial.content}
                  className="format-markdown"
                />
                <h6 className="text-center font-weight-bold text-sec">
                  {testimonial.name}
                </h6>
                <h6 className="text-muted font-italic">
                  <small>
                    {testimonial.testimonial.position} -{" "}
                    {testimonial.testimonial.company}
                  </small>
                </h6>
              </blockquote>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  )
}

export default Testimonials
